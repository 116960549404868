.ContainerStyles {
  height: calc(100vh - 77.5px);
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CameraBox {
  position: relative;
  height: 100vh;
  width: 75vw;
  padding-top: 35px;
}

.Camera {
  height: 75vh;
  width: 75vw;
}

.Overlay {
  position: absolute;
/*  width: 80vw;*/
  height: 75vh;
  max-height: 75vh;
  max-width: 75w;
  opacity: 0.5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: scale-down;
}
